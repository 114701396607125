'use strict';

(function () {
  class ContactImportModalCtrl {
    constructor($scope, $uibModalInstance, Auth, Site, Contact, User, Util, Organization, options, usSpinnerService, Upload, Image, toastr) {
      this.Util = Util;
      this.Site = Site;
      this.Contact = Contact;
      this.User = User;
      this.Organization = Organization;
      this.Upload = Upload;
      this.Image = Image;
      this.toastr = toastr;
      this.isAdmin = Auth.isAdmin;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.isSuccess = false;
      this.submitted = false;
      this.popup = {
        opened: false
      };
      this.currentUser = Auth.getCurrentUser();
      this.init();
    }

    init() {
      if(this.options.organization) {
        this.organization = angular.copy(this.options.organization);
      }
    }

    saveResource(form) {
      this.submitted = true;
      this.serverError = null;
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        //this.resource.tags = this.tags;
        console.log('resource', this.resource);
        this.uploadFiles(this.resource.file, (err, filePath) => {
          if (!err) {
            console.log('filePath', filePath);
            console.log('filename', this.resource.file.name);
            const insertResourceData = {
              id: this.organization,
              filepath: filePath,
              filename: this.resource.file.name
            }
            this.Contact.importContact(insertResourceData).$promise
              .then(resourceResponse => {
                console.log('resourceResponse', resourceResponse);
                this.stopSpin('spinner-1');
                this.isSuccess = true;
                this.$uibModalInstance.close(resourceResponse);
              })
              .catch(err => {
                console.log('Error inserting resource', err);
                this.isDisabled = false;
                this.errors = {};
                this.stopSpin('spinner-1');
                this.handleError(err, form);
              });
          } else {
            console.log('upload image failed:', err);
            this.toastr.error('Something went wrong while image uploading. please try again later.');
            this.isDisabled = false;
            this.errors = {};
            this.stopSpin('spinner-1');
            this.handleError(err, form);
          }
        })
      }
    }

    uploadFiles(file, cb) {
      if (!file || !file.name) {
        cb({ success: false });
      }
      var filename = file.name;
      var id = this.organization;
      console.log("name", id);
      var type = 'client';
      console.log(type);
      var query = {
        type: type,
        id: id,
        filename: filename,
        //resourceData: this.options && this.options.resourceData,
      };
      this.isUploading = true;
      this.Image.contactResource({}, query).$promise
        .then(result => {
          this.Upload.upload({
            url: result.url, //s3Url
            transformRequest: function (data, headersGetter) {
              var headers = headersGetter();
              delete headers.Authorization;
              return data;
            },
            fields: result.fields, //credentials
            method: 'POST',
            file: file
          }).progress((evt) => {
            this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
          }).success((data, status, headers, config) => {
            this.isUploading = false;
            const msg = 'Your File saved successfully. It will be process soon.'
            this.toastr.success(msg);
            // file is uploaded successfully
            cb(null, result.url + '/' + result.fields.key);
          }).error((err) => {
            this.isUploading = false;
            console.log('err', err);
            this.toastr.success('Files was not uploaded to cloud. please try again later or contact administrator');
            cb(err);
          });
        });
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log(err);
      }
    }


    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('ContactImportModalCtrl', ContactImportModalCtrl);
})();
